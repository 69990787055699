/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Resource from 'components/hoc/Resource';
import { Link } from 'react-router-v4';
import autoUpdatesAttachments from 'components/hoc/autoUpdatesAttachments';
import spinnerIfFetching from 'components/hoc/spinnerIf';
import FactoringPaymentEditFormBroker from 'components/pure/form/FactoringPaymentEditFormBroker';
import moment from 'moment';
import getPhoneParts from 'helpers/getPhoneParts';
import formatAddressFields from 'helpers/formatAddressFields';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import {
    mapProps,
    lifecycle,
    withState,
    withHandlers,
    shouldUpdate,
    shallowEqual,
} from 'recompose';
import {
    get,
    pick,
    isEmpty,
    head,
    isArray,
    flatMap,
    includes,
    has,
    concat,
    mapKeys,
    map,
    isEqual,
    isNull,
    find,
} from 'lodash';
import {
    edit,
    fetchCollated,
    fetchInvoice,
    updateAttachment,
} from 'actions/resource/factoringpayment';
import { openModal, closeModal } from 'actions/ui';
import { change, getFormValues, reset, isSubmitting } from 'redux-form';
import getSelf from 'selectors/getSelf';
import convertToString from 'helpers/convertAttachmentCategoriesToString';
import awsImagesUpload from 'helpers/awsImageUpload';
import formatError from 'helpers/formatError';
import SubmissionError from 'datatypes/error/SubmissionError';
import { fetchTerms } from 'actions/factoring';
import getBrokerTerms from '../../../../selectors/getBrokerTerms';
import { NOAStatus } from '../../FactoringCreditCheck';
import { FORM_NAME as FORM_CREATE_FUEL_ADVANCE } from '../../../pure/form/CreateFuelAdvanceForm';
import {
    createPurchase,
    fundingRequestCalculations,
    sendInvoice,
} from '../../../../actions/factoring';
import isTmsUser from '../../../../selectors/isTmsUser';
import { getBrokerGroupCarriers } from '../../../../actions/groups';
import { deleteAttachment } from 'actions/resource/factoringpayment';
import segmentEvents from 'apps/comfreight-frontend/src/js/helpers/segmentEvents';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import {
    FUNDING_REQUEST_CONTRACT_TYPES,
    NO_DEBTOR_ID,
} from '../../../../helpers';
import SelfFinanceCalculation from '../../../hoc/SelfFinanceCalculation';

const FORM_NAME = 'FactoringPaymentEditFormBroker';

const getCarrier = (payload) => {
    if (isEmpty(payload.payment_profile)) {
        return {};
    }
    return {
        created: payload.payment_profile.created,
        discount_rate: payload.discount_rate,
        factoring: payload.factoring,
        payment_profile_id: payload.payment_profile.id,
        payment_profile: payload.payment_profile,
        factoring_fee_split_rate: payload.factoring_fee_split_rate,
        factoring_id: payload.factoring.id,
        co_brokering_approval: get(
            payload,
            'factoring_payment_profile_relation.co_brokering_approval',
        ),
        authority: get(payload, 'payment_profile.authority'),
    };
};
const styleSubTitle = {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000000',
};
const statusStyle = {
    fontWeight: '500',
};

const getStatus = (payment) => {
    switch (payment) {
        case 'pending':
            return { status: 'Pending', color: '#f5a623' };
        case 'incomplete':
            return { status: 'Incomplete', color: '#d0021b' };
        case 'approved':
            return { status: 'Approved', color: '#4dc527' };
        case 'doc_issue':
            return { status: 'Doc Issue', color: '#d0021b' };
        case 'declined':
            return { status: 'Declined', color: '#d0021b' };
        case 'paid':
            return { status: 'Paid', color: '#d0021b' };
        default:
            return { status: 'Pending', color: '#f5a623' };
    }
};

const FactoringPaymentEditBroker = (props) => {
    const paidStatus = getStatus(
        get(props, 'item.data.carrier_payment_status', undefined),
    );
    const marginStatus = getStatus(
        get(props, 'item.data.broker_margin_status', undefined),
    );
    const fuelAdvanceStatus = getStatus(
        get(props, 'item.data.fuel_advance_status', undefined),
    );
    return (
        <div
            className="container"
            style={{ paddingTop: 10, paddingBottom: 10 }}
        >
            <h3 style={{ color: 'rgb(33, 150, 243)' }}>
                <b>Funding Request</b>
            </h3>
            <h4 style={styleSubTitle}>
                Carrier’s Pay Status:{' '}
                <span style={{ color: paidStatus.color, ...statusStyle }}>
                    <i>{paidStatus.status}</i>
                </span>
            </h4>
            {fuelAdvanceStatus && (
                <h4 style={styleSubTitle}>
                    Fuel Advance Status:{' '}
                    <span
                        style={{
                            color: fuelAdvanceStatus.color,
                            ...statusStyle,
                        }}
                    >
                        <i>{fuelAdvanceStatus.status}</i>
                    </span>
                </h4>
            )}
            <h4 style={styleSubTitle}>
                Your Margin Status:{' '}
                <span style={{ color: marginStatus.color, ...statusStyle }}>
                    <i>{marginStatus.status}</i>
                </span>{' '}
            </h4>
            <h4 style={styleSubTitle}>
                NOA w/Debtor Status:{' '}
                <span style={{ color: marginStatus.color, ...statusStyle }}>
                    <i>
                        <NOAStatus
                            data={{
                                ...get(
                                    props,
                                    'item.data.factoring_debtor_relation',
                                    {},
                                ),
                                id: get(props, 'item.data.debtor.id', null),
                            }}
                        />
                    </i>
                </span>{' '}
            </h4>
            <FactoringPaymentEditFormBroker {...props} />
        </div>
    );
};
const formatAccountBank = (account, type) =>
    `${type || ''} ${account ? `(xxxx - ${account})` : ''}`;
const carrierPaymentMethod = (
    status,
    selectedPaymentMethod = {},
    hasFactoring,
    factoringComapnyAccountNumber,
    paymentProfileDisplayedAccountNumber,
    carrier,
) => {
    if (status !== 'carrier_paid' && hasFactoring) {
        return `${get(
            carrier,
            'payment_profile.factoring_company.name',
            '',
        ).toUpperCase()} ${formatAccountBank(
            (selectedPaymentMethod || {}).account_number_redacted,
            (selectedPaymentMethod || {}).method,
        )}`;
    }
    return factoringComapnyAccountNumber ===
        paymentProfileDisplayedAccountNumber
        ? `${get(
              carrier,
              'payment_profile.factoring_company.name',
              '',
          ).toUpperCase()} ${formatAccountBank(
              (selectedPaymentMethod || {}).account_number_redacted,
              (selectedPaymentMethod || {}).method,
          )}`
        : formatAccountBank(
              (selectedPaymentMethod || {}).account_number_redacted,
              (selectedPaymentMethod || {}).method,
          );
};
export default compose(
    Resource('factoringpayment', {
        idPropName: ['params', 'id'],
    }),
    spinnerIfFetching((props) => isEmpty(get(props, 'item.data'))),
    mapProps((props) => {
        const data = get(props, 'item.data', {});
        const factoring_company_name = get(
            data,
            'payment_profile.factoring_company.name',
            undefined,
        );
        const status = get(data, 'status', '');
        const disablePurchaseOrderNumber = [
            'approved',
            'invoice_paid',
            'carrier_paid',
            'paid',
        ].includes(status);
        const carrier = getCarrier(data);
        const carrierDiscountRate =
            ((100 - get(data, 'factoring_fee_split_rate', 0)) *
                get(data, 'discount_rate', 0)) /
                100 || 0;
        const carrierRatePercentage = carrierDiscountRate / 100;
        const carrierFee =
            get(data, 'payment_profile_amount', 0) * carrierRatePercentage;
        const brokerFee =
            get(data, 'payment_profile_amount', 0) *
            ((get(data, 'discount_rate', 0) *
                (data.factoring_fee_split_rate / 100)) /
                100);
        const hasFactoring = Boolean(get(data, 'factoring_company', null));
        const selfFinanceRequested = Boolean(
            get(data, 'self_finance_requested', false),
        );
        const factoringComapnyAccountNumber = get(
            data,
            'payment_profile.factoring_company.account_number',
            '',
        );
        const accounts = get(data, 'payment_profile.accounts', []);
        const selectedPaymentMethod = hasFactoring
            ? get(data, 'payment_profile_payment_method_display', {})
            : find(
                  accounts,
                  (account) =>
                      account.method ===
                          data.payment_profile_payment_method.toUpperCase() &&
                      account.default,
              );
        const paymentProfileDisplayedAccountNumber = get(
            selectedPaymentMethod,
            'account_number_redacted',
            '',
        );
        const paymentProfileAccount = carrierPaymentMethod(
            status,
            selectedPaymentMethod,
            hasFactoring,
            factoringComapnyAccountNumber,
            paymentProfileDisplayedAccountNumber,
            carrier,
        );
        const factoringCreateNonFactoredFr = get(
            data,
            `factoring.create_non_factored_fr`,
            false,
        );
        const enabledContractTypes = get(
            data,
            `factoring.enabled_contract_types`,
            false,
        );
        const allowSelfFinanceFr = get(
            data,
            `factoring.allow_self_finance_fr`,
            false,
        );
        const allowSelfFinanceFrDeclinedDebtors = get(
            data,
            `factoring.allow_self_finance_fr_declined_debtors`,
            false,
        );
        const allowSelfFinanceFrApprovedDebtors = get(
            data,
            `factoring.allow_self_finance_fr_approved_debtors`,
            false,
        );
        const enableHideDebtor = get(
            data,
            `factoring.enable_hide_debtor`,
            false,
        );
        const allowLineItemizedItems = get(
            data,
            `factoring.allow_line_itemized_items`,
            false,
        );
        const selfFinanceFees = get(props, 'selfFinanceCalculationData', {});
        const debtor = {
            ...get(data, 'debtor', {}),
            co_broker_allowed: get(
                data,
                'factoring_debtor_relation.co_broker_allowed',
            ),
        };
        const useNewNFRequest = get(
            data,
            `factoring.use_new_non_factored_funding_requests`,
            false,
        );
        const useNewFRequest = get(
            data,
            `factoring.use_new_broker_funding_requests`,
            false,
        );
        const enableHoldReviewFundingRequestStatus = get(
            data,
            `factoring.enable_hold_review_funding_request_status`,
            false,
        );
        return {
            ...props,
            form: FORM_NAME,
            loadMetadata: get(data, 'metadata', {}),
            canSendInvoice: [
                'remote_approved',
                'approved',
                'incomplete',
                'document_issue',
                'pending_delivery',
                'special_pending',
            ].includes(status),
            disableCarrierNotes: [
                'approved',
                'paid',
                'carrier_paid',
                'invoice_paid',
            ].includes(status),
            disableAll: [
                'approved',
                'remote_approved',
                'paid',
                'carrier_paid',
                'invoice_paid',
            ].includes(status),
            disableDeleteAll: [
                'declined',
                'remote_approved',
                'paid',
                'approved',
                'carrier_paid',
                'invoice_paid',
                'closed',
            ].includes(status),
            enableCarrierUpdate: [
                'incomplete',
                'pending_delivery',
                'special_pending',
                'non_factored_carrier',
            ].includes(status),
            disablePayment: !['new', 'pending'].includes(status),
            disableUpdateStatus: get(data, 'is_locked'),
            disableLoadNumber: get(
                data,
                'factoring.auto_generate_load_numbers',
                false,
            ),
            disablePurchaseOrderNumber,
            factoring_id: data.id,
            factoring_company_name,
            status,
            invoiceSentCount: get(data, 'invoice_sent_count', 0),
            carrier,
            hasFactoring,
            selfFinanceRequested,
            factoringCreateNonFactoredFr,
            enabledContractTypes,
            allowSelfFinanceFr,
            allowSelfFinanceFrDeclinedDebtors,
            allowSelfFinanceFrApprovedDebtors,
            allowLineItemizedItems,
            useNewNFRequest,
            useNewFRequest,
            enableHideDebtor,
            initialValues: {
                ...pick(data, [
                    'payment_profile_id',
                    'amount',
                    'invoice_number',
                    'user_load_number',
                    'first_origin_location',
                    'final_destination_location',
                    'load_length',
                    'load_trailer_type',
                    'bill_to_address',
                    'payment_method',
                    'carrier_amount',
                    'status',
                    'client_invoice_amount',
                    'amount_funded',
                    'bill_to_company_phone',
                    'bill_to_company_email',
                    'status_note',
                    'payment_profile_amount',
                    'payment_profile_unpaid_purchase_amount',
                    'user_notes',
                    'factoring_unpaid_purchase_amount',
                    'factoring_amount',
                    'payment_profile_purchase_amount',
                    'fuel_advance_amount',
                    'reserve_holdback',
                    'carrier_pro_number',
                    'factoring_discount_amount',
                    'factored',
                    'user_reference_number',
                    'payment_profile_amount_funded',
                    'payout_days',
                    'carrier_invoice_notes',
                    'debtor_invoice_notes',
                    'apply_reserve_holdback',
                    'self_finance_requested',
                    'purchase_order_number',
                    'line_items',
                ]),
                debtor,
                factoring_fee_split_rate: parseInt(
                    get(data, 'factoring_fee_split_rate', 100),
                ),
                first_origin_location: formatAddressFields(
                    data.first_origin_location,
                ),
                final_destination_location: formatAddressFields(
                    data.final_destination_location,
                ),
                created: moment(data.created).format('l LT'),
                funded_at: data.funded_at
                    ? moment(data.funded_at).format('l LT')
                    : null,
                scheduled_purchase_date: data.scheduled_purchase_date
                    ? moment(data.scheduled_purchase_date).format('l LT')
                    : null,
                broker_funded_at: data.broker_funded_at
                    ? moment(data.broker_funded_at).format('l LT')
                    : null,
                carrier_funded_at: data.carrier_funded_at
                    ? moment(data.carrier_funded_at).format('l LT')
                    : null,
                carrier,
                Carrier_fee: carrierFee,
                broker_fee: brokerFee,
                carrier_rate: carrierDiscountRate.toFixed(2),
                cropped_attachments: [],
                updated_attachments: data.attachments || [],
                paymentProfileAccount,
                funding_request_type: data.contract_type,
                contract_type: data.contract_type,
                allowLineItemizedItems,
                enableHideDebtor,
                useNewNFRequest,
            },
            enableHoldReviewFundingRequestStatus,
        };
    }),
    withState('attachmentToCrop', 'setAttachmentToCrop', {}),
    withState('refresh', 'setRefresh', false),
    withState('newAttachments', 'setNewAttachments', []),
    withState('deletedAttachments', 'setDeletedAttachments', []),
    withState(
        'invoiceCount',
        'setInvoiceCount',
        ({ invoiceSentCount }) => invoiceSentCount,
    ),
    withState('storedState', 'setStoredState', null),
    connect(
        (state, ownProps) => {
            return {
                updatedFormValues: getFormValues(FORM_NAME)(
                    state,
                    'amount',
                    'payment_profile_amount',
                    'payout_days',
                    'factoring_fee_split_rate',
                    'purchase_order_number',
                ),
                formValues: getFormValues(FORM_NAME)(
                    state,
                    'amount',
                    'payment_profile_amount',
                    'debtor',
                    'carrier',
                    'factoring_fee_split_rate',
                    'payout_days',
                    'payment_profile_unpaid_purchase_amount',
                    'factored',
                    'Carrier_fee',
                    'broker_fee',
                    'carrier_rate',
                    'cropped_attachments',
                    'fuel_advance_amount',
                    'paymentProfileAccount',
                    'apply_reserve_holdback',
                    'self_finance_requested',
                    'status',
                    'funding_request_type',
                    'discount_rate_comfreight',
                ),
                terms: getBrokerTerms(state),
                self: getSelf(state),
                factoring: get(state, 'user.factoring.data', {}),
                initialValues: {
                    ...ownProps.initialValues,
                    require_signed_bol: getSelf(state).data.require_signed_bol,
                    allowFrWithoutCarrier: get(
                        state,
                        'user.factoring.data.allow_fr_without_carrier',
                        false,
                    ),
                },
                isTmsUser: isTmsUser(state),
                isSubmittingCreateFuelAdvanceForm: isSubmitting(
                    FORM_CREATE_FUEL_ADVANCE,
                )(state),
                carrier_mc: get(
                    getFormValues(FORM_NAME)(state),
                    ['carrier', 'payment_profile', 'company_mc'],
                    '',
                ),
                carrier_email: get(
                    getFormValues(FORM_NAME)(state),
                    ['carrier', 'payment_profile', 'owner_email'],
                    '',
                ),
                carrier_phone: get(
                    getFormValues(FORM_NAME)(state),
                    ['carrier', 'payment_profile', 'owner_phone_number_cell'],
                    '',
                ),
                updatedStatus: get(
                    getFormValues(FORM_NAME)(state),
                    ['status'],
                    '',
                ),
            };
        },
        (
            dispatch,
            {
                form,
                item: {
                    data: { id, ...data },
                },
                setInvoiceCount,
                invoiceCount,
            },
        ) => ({
            openLineItemsModal: () =>
                dispatch(
                    openModal('LineItemsModal', {
                        modalSize: 'meduim',
                    }),
                ),
            sendInvoice: () => {
                try {
                    dispatch(
                        openModal('confirm', {
                            type: 'sendInvoice',
                            args: [
                                {
                                    onConfirm: async () => {
                                        try {
                                            const res = await dispatch(
                                                sendInvoice(id),
                                            );
                                            if (
                                                get(
                                                    res,
                                                    'email_not_verified',
                                                    '',
                                                )
                                            ) {
                                                dispatch(
                                                    openModal('warning', {
                                                        message:
                                                            "This debtor's email has not been verified yet so an invoice cannot be sent at this time. We will verify it in 1-2 business days. If your debtor is set to receive invoices from us automatically then they will still receive their invoice once the funding request is approved even if you don't come back to send it from here later.",
                                                    }),
                                                );
                                                return false;
                                            }
                                            setInvoiceCount(invoiceCount + 1);
                                            return true;
                                        } catch (e) {
                                            throw e;
                                        }
                                    },
                                },
                            ],
                        }),
                    );
                } catch (e) {
                    console.warn(e);
                    dispatch(
                        openModal('error', {
                            message:
                                "Couldn't send invoice at this time. Please try again later.",
                        }),
                    );
                }
            },
            fetchTerms: (...args) => dispatch(fetchTerms(...args)),
            change: (fieldName, value) =>
                dispatch(change(FORM_NAME, fieldName, value)),
            fetchInvoice(...args) {
                try {
                    return dispatch(fetchInvoice(...args));
                } catch (err) {
                    console.warn(err);
                    dispatch(
                        openModal('error', {
                            message:
                                "Couldn't fetch invoice at this time. Please try again later.",
                        }),
                    );
                }
            },
            fetchCollated(...args) {
                try {
                    return dispatch(fetchCollated(...args));
                } catch (err) {
                    console.warn(err);
                    dispatch(
                        openModal('error', {
                            message:
                                "Couldn't fetch pdf at this time. Please try again later.",
                        }),
                    );
                }
            },
            deleteFactoringPayment(...args) {
                return dispatch(
                    openModal('confirm', {
                        type: 'factoringpayment_delete',
                        args: [...args],
                    }),
                );
            },
            deleteAttachment(id, attachment) {
                return dispatch(
                    openModal('confirm', {
                        type: 'factoringpaymentattachment_delete',
                        args: [id, attachment],
                    }),
                );
            },
            dispatch: (...args) => dispatch(...args),
            async onSubmitCreateFuelAdvance(fields, dispatch, props) {
                try {
                    const data = {
                        amount: fields.fuel_advance_amount,
                        fuel_advance_fee_split_rate: fields.broker_rate,
                        payment_method: fields.payment_method,
                    };
                    await dispatch(createPurchase(id, data));
                    dispatch(closeModal());
                    dispatch(
                        openModal('success', {
                            message: 'Successfully Fuel Advance added .',
                        }),
                    );
                    dispatch(
                        change(
                            FORM_NAME,
                            'fuel_advance_amount',
                            fields.fuel_advance_amount,
                        ),
                    );
                    dispatch(change(FORM_NAME, 'status', 'fuel_advance'));
                } catch (e) {
                    dispatch(
                        openModal('error', {
                            message: 'Failed to create fuel advance.',
                        }),
                    );
                }
            },
            async onSubmit(fields, dispatch, props) {
                try {
                    if (
                        fields.status ===
                            USER_FACTORING_STATUS.REMOTE_APPROVED &&
                        get(fields, 'carrier.authority') === 'BROKER_AUTH' &&
                        (get(fields, 'carrier.co_brokering_approval') !==
                            'APPROVED' ||
                            get(fields, 'debtor.co_broker_allowed') !==
                                'APPROVED')
                    ) {
                        return dispatch(
                            openModal('error', {
                                message:
                                    'You cannot Approve this Funding Request, Please upload required co-broker documents',
                            }),
                        );
                    }
                    if (
                        !isEmpty(props.formWarning) &&
                        fields.status === USER_FACTORING_STATUS.REMOTE_APPROVED
                    ) {
                        await new Promise((resolve, reject) =>
                            dispatch(
                                openModal('WarningDuplicatePO', {
                                    action: () => {
                                        dispatch(closeModal());
                                        resolve(true);
                                    },
                                    closeAction: () =>
                                        setTimeout(() => reject(true), 200),
                                    invoices: props.poRelatedInvoice,
                                }),
                            ),
                        );
                    }
                    if (
                        !isEmpty(
                            get(fields, 'carrier.payment_profile_id', ''),
                        ) && fields.contract_type === 'STD_BROKER' &&
                        isEmpty(props.loadMetadata)
                    ) {
                        const res = await dispatch(
                            getBrokerGroupCarriers(get(props, 'factoring.id'), {
                                payment_profile_id: get(
                                    fields,
                                    'carrier.payment_profile_id',
                                    '',
                                ),
                            }),
                        );
                        const isRestoringValues =
                            props.initialValues?.contract_type ===
                                'NON_FACTORED_STANDARD' &&
                            fields.contract_type === 'STD_BROKER' &&
                            fields.payout_days ===
                                get(
                                    props.loadMetadata,
                                    'previous_payout_days',
                                ) &&
                            fields.factoring_fee_split_rate ===
                                get(
                                    props.loadMetadata,
                                    'previous_factoring_fee_split_rate',
                                );
                        if (!isRestoringValues && (res.results || []).length) {
                            const carrier = get(res, 'results[0]', {});
                            if (
                                carrier.payout_days !== fields.payout_days ||
                                carrier.factoring_fee_split_rate !==
                                    fields.factoring_fee_split_rate
                            ) {
                                await new Promise((resolve, reject) =>
                                    dispatch(
                                        openModal(
                                            'ConfirmationFRPaymentSpeed',
                                            {
                                                modalSize: 'small',
                                                defaultValue: {
                                                    payout_days:
                                                        carrier.payout_days,
                                                    factoring_fee_split_rate:
                                                        carrier.factoring_fee_split_rate,
                                                },
                                                currentValue: {
                                                    payout_days:
                                                        fields.payout_days,
                                                    factoring_fee_split_rate:
                                                        fields.factoring_fee_split_rate,
                                                },
                                                initialValue: {
                                                    payout_days: get(
                                                        props,
                                                        'initialValues.payout_days',
                                                    ),
                                                    factoring_fee_split_rate:
                                                        get(
                                                            props,
                                                            'initialValues.factoring_fee_split_rate',
                                                        ),
                                                },
                                                handleSubmit: () => {
                                                    dispatch(closeModal());
                                                    setTimeout(
                                                        () => resolve(true),
                                                        200,
                                                    );
                                                },
                                                closeModal: () => {
                                                    dispatch(closeModal());
                                                    setTimeout(
                                                        () => reject(true),
                                                        200,
                                                    );
                                                },
                                                update:
                                                    get(
                                                        props,
                                                        'initialValues.factoring_fee_split_rate',
                                                    ) !==
                                                        fields.factoring_fee_split_rate ||
                                                    get(
                                                        props,
                                                        'initialValues.payout_days',
                                                    ) !== fields.payout_days,
                                            },
                                        ),
                                    ),
                                );
                            }
                        }
                    }
                    if (
                        fields.status === 'non_factored_carrier' &&
                        props.status !== 'non_factored_carrier' &&
                        isEmpty(props.carrier)
                    ) {
                        return await new Promise((resolve, reject) =>
                            dispatch(
                                openModal('error', {
                                    message:
                                        'This type of non-factored status requires a carrier be added to the funding request. Once the carrier is added you will be able to complete your update.',
                                    customCloseModal: () => {
                                        dispatch(closeModal());
                                        resolve(true);
                                    },
                                }),
                            ),
                        );
                    }
                    if (
                        fields.status === 'non_factored_carrier' &&
                        props.status !== 'non_factored_carrier'
                    ) {
                        await new Promise((resolve, reject) =>
                            dispatch(
                                openModal('warning', {
                                    message:
                                        'If you save with this Non-Factored Pay Carrier status, we will pay you AND your carrier once the shipper/debtor pays the invoice. Please make sure that you are not going to pay the carrier before confirming the save of this update status so that the carrier is not double-paid.',
                                    customCloseModal: () => {
                                        dispatch(closeModal());
                                        resolve(true);
                                    },
                                    displayCloseModal: true,
                                    buttonChildren: 'Submit',
                                    customButtonStyle: 'btn-orange',
                                }),
                            ),
                        );
                    }
                    if (
                        fields.status === USER_FACTORING_STATUS.REMOTE_APPROVED &&
                        fields.contract_type !== FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_STANDARD &&
                        fields.contract_type !== FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_BILL_OUT
                    ) {
                        const declinedDebtor = [
                            USER_FACTORING_STATUS.DECLINED,
                            USER_FACTORING_STATUS.DECLINED_3_MONTHS,
                            USER_FACTORING_STATUS.DECLINED_6_MONTHS,
                        ].includes(get(fields, 'debtor.credit_approved', ''));
                        if (
                            (declinedDebtor &&
                                !fields.self_finance_requested) ||
                            (declinedDebtor &&
                                fields.self_finance_requested &&
                                !props.allowSelfFinanceFrDeclinedDebtors)
                        ) {
                            return await new Promise((resolve, reject) =>
                                dispatch(
                                    openModal('warning', {
                                        message: (
                                            <p>
                                                The shipper/debtor is declined
                                                on credit. Please change the
                                                status of the funding request to
                                                Non-Factored Pay Carrier if you
                                                wish to have HaulPay pay your
                                                carrier when the debtor/shipper
                                                pays the invoice. Otherwise,
                                                change the status of the funding
                                                request to Non-Factored Bill Out
                                                Only if you the broker would
                                                like to arrange payment with
                                                your carrier. You may also request
                                                for credit to be extended to this
                                                debtor but it may not be approved. 
                                                If you have any questions or 
                                                concerns please contact support at
                                                <Link
                                                    to=""
                                                    onClick={(e) => {
                                                        window.location.href =
                                                            'mailto:support@haulpay.io';
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    {` support@haulpay.io`}
                                                </Link>
                                                .
                                            </p>
                                        ),
                                        customCloseModal: () => {
                                            dispatch(closeModal());
                                            resolve(true);
                                        },
                                    }),
                                ),
                            );
                        }
                        const all = get(fields, 'cropped_attachments', [])
                            .concat(get(fields, 'attachments', []))
                            .concat(get(fields, 'updated_attachments', []));
                        const categories = flatMap(all, ({ category }) => {
                            if (isArray(category)) {
                                return category;
                            }
                            if (category) {
                                return category.split(',');
                            }
                        });
                        if (
                            !includes(categories, 'Truck Order Not Used') &&
                            fields.require_signed_bol &&
                            !includes(
                                categories,
                                'Signed Bill of Lading (Delivered)',
                            ) &&
                            fields.funding_request_type !==
                                'SELF_FINANCE_NO_INVOICE'
                        ) {
                            return dispatch(
                                openModal('error', {
                                    message:
                                        'Please upload a Signed Bill of Lading - Delivered that can be used and accepted by the shipper-debtor for invoicing before setting this funding request to Approved. ',
                                }),
                            );
                        }
                    }
                    if (
                        fields.status === 'non_factored' &&
                        props.status !== 'non_factored'
                    ) {
                        await new Promise((resolve, reject) =>
                            dispatch(
                                openModal('warning', {
                                    message:
                                        'If you save this with the Non-Factored status, we will bill the shipper/debtor and pay you later. We will not pay the carrier. Do you still want to change this funding request to this status? ',
                                    customCloseModal: () => {
                                        dispatch(closeModal());
                                        resolve(true);
                                    },
                                    displayCloseModal: true,
                                    buttonChildren: 'Submit',
                                    customButtonStyle: 'btn-orange',
                                }),
                            ),
                        );
                    }
                    const attachmentsProp = get(
                        props,
                        'item.data.attachments',
                        [],
                    );
                    if (!isEmpty(props.deletedAttachments)) {
                        map(props.deletedAttachments, async (item) => {
                            try {
                                await props.dispatch(
                                    deleteAttachment(id, item.id),
                                );
                            } catch (e) {}
                        });
                        props.setDeletedAttachments([]);
                    }
                    if (!isEmpty(attachmentsProp)) {
                        map(attachmentsProp, async (attachment, index) => {
                            if (
                                !isEqual(
                                    attachment,
                                    fields.updated_attachments[index],
                                )
                            ) {
                                await props.dispatch(
                                    updateAttachment(
                                        id,
                                        fields.updated_attachments[index],
                                    ),
                                );
                            }
                        });
                    }
                    let data = {
                        ...pick(fields, [
                            'amount',
                            'invoice_number',
                            'user_load_number',
                            'load_length',
                            'load_trailer_type',
                            'payment_method',
                            'carrier_amount',
                            'client_invoice_amount',
                            'amount_funded',
                            'factoring_fee_split_rate',
                            'bill_to_company_phone',
                            'bill_to_company_email',
                            'status_note',
                            'payment_profile_amount',
                            'payment_profile_unpaid_purchase_amount',
                            'user_notes',
                            'attachments',
                            'factoring_unpaid_purchase_amount',
                            'factoring_amount',
                            'payout_days',
                            'payment_profile_purchase_amount',
                            'reserve_holdback',
                            'carrier_pro_number',
                            'factoring_discount_amount',
                            'user_reference_number',
                            'carrier_invoice_notes',
                            'debtor_invoice_notes',
                            'apply_reserve_holdback',
                            'purchase_order_number',
                            'self_finance_requested',
                            'line_items',
                            'contract_type',
                        ]),
                        debtor: get(fields, 'debtor.id', NO_DEBTOR_ID),
                        attachments: convertToString(
                            get(fields, 'attachments', []),
                        ),
                        first_origin_location: formatAddressFields(
                            fields.first_origin_location,
                        ),
                        final_destination_location: formatAddressFields(
                            fields.final_destination_location,
                        ),
                        bill_to_address: formatAddressFields(
                            fields.bill_to_address,
                        ),
                        cropped_attachments: convertToString(
                            fields.cropped_attachments,
                        ),
                        payment_profile_id: get(
                            fields,
                            'carrier.payment_profile_id',
                            '',
                        ),
                        ...(() =>
                            !['approved', 'admin_approved'].includes(
                                fields.status,
                            )
                                ? { status: fields.status }
                                : {})(),
                    };
                    if (
                        fields?.useNewNFRequest &&
                        props?.loadMetadata?.previous_payout_days &&
                        (([
                            FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_STANDARD,
                            FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_BILL_OUT,
                        ].includes(props.initialValues?.contract_type) &&
                            fields?.contract_type ===
                                FUNDING_REQUEST_CONTRACT_TYPES.STD_BROKER) ||
                            ([
                                FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_STANDARD,
                                FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_BILL_OUT,
                            ].includes(fields?.contract_type) &&
                                props.initialValues?.contract_type ===
                                    FUNDING_REQUEST_CONTRACT_TYPES.STD_BROKER))
                    ) {
                        data = {
                            ...data,
                            payment_profile:
                                props?.loadMetadata
                                    ?.previous_payment_profile_id,
                            factoring_fee_split_rate:
                                props?.loadMetadata
                                    ?.previous_factoring_fee_split_rate,
                            invoice_amount:
                                props?.loadMetadata
                                    ?.previous_payment_profile_amount,
                            payout_days:
                                props?.loadMetadata?.previous_payout_days === 0
                                    ? 'ach_1_day'
                                    : `ach_${props?.loadMetadata?.previous_payout_days}_day`,
                        };
                    }
                    dispatch(edit(id, data))
                        .then(async (result) => {
                            const isNonFactoredFundingRequest =
                                [
                                    FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_STANDARD,
                                    FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_BILL_OUT,
                                ].includes(fields.contract_type) ||
                                [
                                    USER_FACTORING_STATUS.NON_FACTORED,
                                    USER_FACTORING_STATUS.NON_FACTORED_CARRIER,
                                ].includes(fields.status);
                            const factored =
                                !isNonFactoredFundingRequest || fields.factored;
                            const data = {
                                funding_request_id: id,
                                invoice_amount: fields.amount,
                                debtor: fields.debtor.id,
                                self_finance_requested:
                                    fields.self_finance_requested,
                                factored,
                                apply_reserve_holdback:
                                    fields.apply_reserve_holdback,
                                contract_type: fields.funding_request_type,
                                factoring_fee_split_rate: Number(
                                    fields.factoring_fee_split_rate,
                                ),
                                create_rates_revision: true,
                                ...(() =>
                                    factored
                                        ? {
                                              payment_profile_invoice_amount:
                                                  fields.payment_profile_amount,
                                              payment_speed: parseInt(
                                                  fields.payout_days.match(
                                                      /\d+/g,
                                                  )[0],
                                                  0,
                                              ),
                                              payment_profile:
                                                  fields.carrier
                                                      .payment_profile_id,
                                              factoring_fee_split_rate: Number(
                                                  fields.factoring_fee_split_rate,
                                              ),
                                          }
                                        : {})(),
                            };
                            await dispatch(fundingRequestCalculations(data));
                            if (
                                fields.amount !==
                                get(props, 'initialValues.amount')
                            ) {
                                window.analytics.track(
                                    segmentEvents.BROKER_EDIT_FUNDING_REQUEST_INVOICE_AMOUNT,
                                );
                            }
                            const all = get(
                                fields,
                                'cropped_attachments',
                                [],
                            ).concat(get(fields, 'attachments', []));
                            const attachments = get(
                                result,
                                'payload.json.attachments',
                                [],
                            );
                            const files = attachments
                                .map((attachment) => {
                                    const file = head(
                                        all.filter(
                                            (file) =>
                                                file.filename ===
                                                attachment.filename,
                                        ),
                                    );
                                    return {
                                        upload_url: attachment.upload_url,
                                        file: file,
                                    };
                                })
                                .filter(
                                    ({ upload_url, file }) =>
                                        upload_url && file,
                                );
                            if (files.length) {
                                window.analytics.track(
                                    segmentEvents.USER_UPLOAD_NEW_DOCUMENTS_FUNDING_REQUEST,
                                );
                            }
                            return awsImagesUpload(files);
                        })
                        .then(() => {
                            dispatch(
                                openModal('success', {
                                    message: 'Successfully edited transaction',
                                }),
                            );
                            if (
                                fields.status === 'non_factored' &&
                                props.status !== 'non_factored'
                            ) {
                                window.location.reload(false);
                            }
                        })
                        .catch((err) => {
                            if (err instanceof SubmissionError) {
                                if (
                                    get(
                                        err,
                                        ['message', 'payment_profile', '0'],
                                        '',
                                    ).includes(
                                        "The Carrier on the Funding Request has not yet been Approved by HaulPay. Please contact our team at support@haulpay.io to get the status of the Carrier's review process",
                                    )
                                ) {
                                    dispatch(
                                        openModal('error', {
                                            message:
                                                'Cannot approve FR for a Carrier Payment Profile that is not in an APPROVED status, please check to see if the Carrier has a duplicate profile. If no duplicate profile exist, please reach out to invitedcarriers@haulpay.io for additional assistance.',
                                        }),
                                    );
                                    throw err;
                                }
                                if (
                                    get(
                                        err,
                                        ['message', 'non_field_errors', '0'],
                                        '',
                                    ).includes(
                                        'Updating to the Document Issue Status requires Issues listed about faulty documents in the STATUS Note Field.',
                                    )
                                ) {
                                    return dispatch(
                                        openModal('error', {
                                            message:
                                                'Please enter a Status Note to update this transaction to Document Issue.',
                                        }),
                                    );
                                }
                            }
                            if (
                                get(err, 'message.status') ===
                                'This Funding Request cannot be updated because it has already been moved to Approved and purchases have been made.'
                            ) {
                                dispatch(
                                    openModal('PaymentFundingRequestError', {
                                        modalSize: 'small',
                                        closeModal: () =>
                                            dispatch(closeModal()),
                                    }),
                                );
                            }
                            if (has(err, 'message') && !isEmpty(err.message)) {
                                let errorMsg = [];
                                mapKeys(err.message, (value, key) => {
                                    errorMsg = concat(errorMsg, value);
                                });
                                return dispatch(
                                    openModal('error', { message: errorMsg }),
                                );
                            }
                            dispatch(
                                openModal('error', {
                                    message: 'Failed to edit payment request.',
                                }),
                            );
                        });
                    props.setRefresh(true);
                    props.dispatch(
                        change(FORM_NAME, 'cropped_attachments', []),
                    );
                    // dispatch(reset(FORM_NAME));
                } catch (e) {
                    console.log('ERROR', e);
                }
            },
        }),
    ),
    autoUpdatesAttachments(),
    withHandlers({
        handleAttachmentCropResult: (props) => (croppedImage) => {
            const {
                attachmentToCrop = {},
                formValues: { cropped_attachments = [] },
                setAttachmentToCrop,
                change,
            } = props;
            setAttachmentToCrop({});
            const currentDatetime = moment().format('YYYYMMDDHHmmss');
            const filenameParts = attachmentToCrop.filename.split('.');
            const filenamePure = filenameParts
                .slice(0, -1)
                .join('.')
                .replace(/_\d{14}_edited/g, '');
            const filenameExtension = filenameParts[filenameParts.length - 1];
            const newFilename =
                `${filenamePure}_${currentDatetime}_edited.${filenameExtension}`.replace(
                    / /g,
                    '_',
                );
            croppedImage.filename = newFilename;
            croppedImage.name = newFilename;
            croppedImage.category = attachmentToCrop.category;
            croppedImage.visible_to = attachmentToCrop.visible_to;
            try {
                change(
                    'cropped_attachments',
                    cropped_attachments.concat(croppedImage),
                );
            } catch (err) {
                console.warn(err);
                props.dispatch(
                    openModal('error', {
                        message:
                            "Couldn't crop attachment image at this time. Please try again later.",
                    }),
                );
            }
        },
    }),
    lifecycle({
        async componentDidUpdate(prevProps) {
            const removeLinks = ({
                download_url,
                preview_url,
                upload_url,
                ...rest
            }) => rest;
            const previousAttachments = get(
                prevProps,
                'item.data.attachments',
                [],
            );
            const currentAttachments = get(
                this.props,
                'item.data.attachments',
                [],
            );
            const currentLastAttachmentUploadTime = get(
                this.props,
                `item.data.attachments[${get(this.props, 'item.data.attachments', []).length - 1}].uploaded`,
            );
            const previousLastAttachmentUploadTime = get(
                prevProps,
                `item.data.attachments[${get(prevProps, 'item.data.attachments', []).length - 1}].uploaded`,
            );
            const currentLastAttachment = removeLinks(
                get(
                    this.props,
                    `item.data.attachments[${get(this.props, 'item.data.attachments', []).length - 1}]`,
                    {},
                ),
            );
            const previousLastAttachment = removeLinks(
                get(
                    prevProps,
                    `item.data.attachments[${get(prevProps, 'item.data.attachments', []).length - 1}]`,
                    {},
                ),
            );
            if (
                previousAttachments.length !== currentAttachments.length ||
                currentLastAttachmentUploadTime !==
                    previousLastAttachmentUploadTime ||
                !isEqual(currentLastAttachment, previousLastAttachment)
            ) {
                this.props.fetchCollated(this.props.factoring_id);
                this.props.setNewAttachments(
                    get(this.props, 'item.data.attachments', []),
                );
                this.props.change(
                    'updated_attachments',
                    get(this.props, 'item.data.attachments', []),
                );
            }
        },
    }),
)(FactoringPaymentEditBroker);
